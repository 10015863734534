import React, { useState, useEffect, FC, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { Controller, FormProvider, set, useForm } from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Drawer,
  Upload,
  Select,
  Popover,
  DatePicker,
  TableColumnsType,
  Timeline,
  Empty,
  UploadFile,
  List,
  Checkbox,
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import QuotationsAside from "./QuotationsAside";
import statusMapper from "../purchase-orders/StatusMapper";
// import QuotationApprovalDrawer from "../invoice/QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../masters/Misc";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import Swal from "sweetalert2";
import usersApis from "../../api/master/users";
import quotationApis from "../../api/master/quotations";
import Dragger from "antd/es/upload/Dragger";
import costCenterApis from "../../api/master/costCenter";
import MaterialModal from "./MaterialModal";
import supplierApis from "../../api/master/supplier";
import {
  formatCurrency,
  formatCurrency2,
  formatDecimal,
} from "../../utils/Utils";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import PdfGenerator from "./SellerToBuyerPDF";
import EmailCreationModal from "../EmailCreationModal";
import FormTextArea from "../form-components/TextAreaComponent";
import FormItem from "antd/es/form/FormItem";

interface SelectOptions {
  label: string;
  value: string;
}

interface AddressDetails {
  id: any;
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateName: string;
  countryName: string;
  pincode: string;
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  const isHSNEditable = dataIndex === "hsn" && !record.hsn; // HSN editable only if record.hsn is undefined or empty

  return (
    <td {...restProps}>
      {editing ? (
        dataIndex === "hsn" && record.hsn ? (
          // If HSN exists, don't make it editable, just display it
          <span>{record.hsn}</span>
        ) : (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: ["hsn", "price", "leadDays"].includes(dataIndex),
                message: `${title} is required.`,
              },
              {
                validator: async (_: any, value: any) => {
                  // Allow cgst, sgst, and igst to be 0
                  if (
                    ["cgst", "igst", "sgst"].includes(dataIndex) &&
                    value === 0
                  ) {
                    return Promise.resolve();
                  }

                  // For minQty, ensure it can be 0, but not greater than supplyQuantity
                  if (dataIndex === "minQty") {
                    const supplyQuantity = record.quantity || 0;
                    if (value > supplyQuantity) {
                      return Promise.reject(
                        `${title} should not be greater than Supply Quantity (${supplyQuantity})`
                      );
                    }
                    return Promise.resolve();
                  }

                  // For leadDays, ensure it is greater than 0
                  if (dataIndex === "leadDays" && (!value || value <= 0)) {
                    return Promise.reject(
                      new Error(`${title} must be greater than 0`)
                    );
                  }

                  // For all other fields, ensure the value is greater than 0
                  if (!value || value <= 0) {
                    return Promise.reject(
                      new Error(`${title} must be greater than 0`)
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            {dataIndex === "hsn" ? (
              <Form.Item
                name={dataIndex + `_${record.rfqLineId}`} // Ensure unique field
                style={{ margin: 0 }}
                rules={[
                  {
                    pattern: /^[0-9]{6,8}$/, // HSN must be between 6 and 8 digits
                    message: "HSN should be a number with 6 to 8 digits",
                  },
                ]}
              >
                <InputNumber
                  className="w-100"
                  parser={(value: any) => value.replace(/\D/g, "")} // Removes non-numeric characters
                  onKeyPress={(event) => {
                    const regex = /^[0-9\b]+$/; // Restrict to numbers and backspace
                    if (!regex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            ) : (
              <InputNumber
                className="w-100"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                min={
                  // Allow minQty to be 0, others must be 1 or more
                  dataIndex === "minQty"
                    ? 0
                    : ["cgst", "igst", "sgst", "price", "leadDays"].includes(
                        dataIndex
                      )
                    ? 0 // Allow decimals for tax fields and price
                    : 1 // Ensure other fields are integers starting at 1
                }
                step={
                  ["cgst", "igst", "sgst", "price", "minQty"].includes(
                    dataIndex
                  )
                    ? 0.01 // Allow decimals for tax fields and price
                    : 1 // Force integer input for other fields
                }
                onKeyPress={(event) => {
                  if (
                    ["cgst", "igst", "sgst", "price", "minQty"].includes(
                      dataIndex
                    )
                  ) {
                    // Allow numbers and decimal point for tax fields and price
                    if (!/^[0-9.]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  } else {
                    // Allow only numbers (no decimals) for other fields
                    if (!/^[0-9]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }
                }}
              />
            )}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};

const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}

const EditQuotedView: FC<any> = () => {
  const pdfBlobData: any = useRef(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareOptionsToggle, setShareOptionsToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let { qid }: any = useParams();
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [rfqStatus, setRfqStatus] = useState<any>("");
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [supplierList, setSupplierList] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [reqDocAttachments, setReqDocAttachments] = useState<any>([]);

  const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
  const [newAttachments, setNewAttachments] = useState<any>([]);
  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const [newInstructions, setNewInstructions] = useState<any>("");
  const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableRegret, setDisableRegret] = useState(false);

  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const [signature, setSignature] = useState<any>([]);

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const instructionsWatch = watch("instructions");
  const termsAndConditionsWatch = watch("termsAndConditions");
  useEffect(() => {
    if (termsAndConditionsWatch) {
      setNewTermsAndConditions(termsAndConditionsWatch);
    }
  }, [termsAndConditionsWatch]);

  useEffect(() => {
    if (instructionsWatch) {
      setNewInstructions(instructionsWatch);
    }
  }, [instructionsWatch]);

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );

  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [billingAddressList, setBillingAddressList] = useState<
    AddressDetails[]
  >([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [selectedArrayData, setSelectedArrayData] = useState([]);

  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });

  const [editingKey, setEditingKey] = useState<any | null>(null);

  const [updatedLineItems, setUpdatedLineItems] = useState<any>([]);

  const [editedData, setEditedData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [newReqAttachment, setNewReqAttachment] = useState<File[]>([]);

  const [fileNameMapping, setFileNameMapping] = useState<Map<string, string>>(
    new Map()
  );

  const isEditing = (record: any) => record.key == editingKey;

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [additionalCharges, setAdditionalCharges]: any = useState<any>([]);
  const [editAdditionalCharges, setEditAdditionalCharges] =
    useState<any>(false);

  const [newRDCAttachment, setNewRDCAttachment] = useState<any>(null);

  const [newNegotiableType, setNewNegotiableType] = useState<any>("");

  const [newValidityDate, setNewValidityDate] = useState<any>();
  const [newPaymentType, setNewPaymentType] = useState<any>("");
  const [newPaymentDays, setNewPaymentDays] = useState<any>("");
  const [newPaymentPercentage, setNewPaymentPercentage] = useState<any>("");
  useEffect(() => {
    fetchDetails();
    // fetchDeliveryAddress();
    fetchCostCenter();
    fetchSites();
    viewChange();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
    setNewAttachments((pre: any) => {
      return [...pre, ...info.fileList];
    });
    setShowSubmitBtm(true);
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchSuppliers = async () => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplier();
      console.log("status:", status);
      if (status) {
        console.log("1111111::::");
        let formatedData = data.map((item: any) => ({
          supplierName: item.supplierName, // Adjust this based on the actual key in your data
          supplierID: item?.supplierOrgID
            ? item?.supplierOrgID?.toString()
            : item?.supplierID?.toString(),
        }));
        console.log("formatedData", formatedData);
        setSuppliers(formatedData);
        return formatedData;
      } else {
        throw new Error(message);
      }
    } catch (error) {}
  };

  const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
    console.log("Line Total::::", data);
    let totalNetAmmount = 0,
      cgst = 0,
      sgst = 0,
      igst = 0,
      TotalAmount = 0;
    data?.forEach((item: any) => {
      const totalAmmount = Number(item.suppliedQty) * Number(item.price);
      cgst = cgst + (totalAmmount * Number(item.cgst)) / 100;
      sgst = sgst + (totalAmmount * Number(item.sgst)) / 100;
      igst = igst + (totalAmmount * Number(item.igst)) / 100;
      totalNetAmmount += totalAmmount;
      console.log("totalAmmount", totalAmmount, totalNetAmmount);
      console.log("cgst", cgst, sgst, igst, totalNetAmmount);
    });
    TotalAmount = totalNetAmmount + cgst + sgst + igst;
    additionalCharges?.forEach((item: any) => {
      TotalAmount = TotalAmount + Number(item.price);
    });
    return (
      <Row
        className={`${invoice && "w-50 mt-2"}`}
        style={{
          margin: "20px",
        }}
      >
        <Col className={`justify-end`}>
          <div className={`${invoice ? "w-100" : "w-40"}`}>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>Taxable Amount</strong>
              </div>
              <span className="r_align">
                {formatCurrency(totalNetAmmount, "INR")}
              </span>
            </div>
            {cgst && sgst ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>CGST</strong>
                  </div>
                  <span className="r_align">{formatCurrency(cgst, "INR")}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>SGST</strong>
                  </div>
                  <span className="r_align">{formatCurrency(sgst, "INR")}</span>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="w-50 justify-end">
                  <strong>IGST</strong>
                </div>
                <span className="r_align">{formatCurrency(igst, "INR")}</span>
              </div>
            )}
            {additionalCharges?.map((item: any) => {
              if (!item.price || !item.price) return null;
              return (
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>{item.name}</strong>
                  </div>
                  <span className="r_align">
                    {formatCurrency(item.price, "INR")}
                  </span>
                </div>
              );
            })}
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              <div className="w-50 justify-end">
                <strong>Total</strong>
              </div>
              <strong className="r_align">
                {formatCurrency(TotalAmount, "INR")}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const updatePdfBlobDataRef = (newData: any) => {
    try {
      pdfBlobData.current = newData;
    } catch (error) {}
  };

  const TriggerEmail = async (emailData: any, _methods: any) => {
    try {
      const formData = new FormData();
      formData.append("msgType", "purchaseOrderConfirmation");
      formData.append("logo", currentUser?.orgLogo);
      formData.append("email", emailData?.to);
      formData.append("cc", emailData?.cc);
      formData.append("bcc", emailData?.bcc);
      if (emailData?.attachments?.length > 0) {
        emailData?.attachments?.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          let file: any = null;
          if (index == 0) {
            file = new File(
              [pdfBlobData.current],
              OriginalData?.supplierHeader?.rfqId + ".pdf",
              {
                type: "application/pdf",
              }
            );
          } else {
            file = attachment.originFileObj;
          }
          formData.append(`files`, file);
        });
      }
      const response: any = await quotationApis.emailNotify(
        OriginalData?.supplierHeader?.supplierQuoteId,
        formData
      );
      const { data, status } = response;
      if (status) {
        Swal.fire("Success", "Email has been sent successfully", "success");
        _methods.reset();
        setShowEmailModal(false);
      }
    } catch (error) {
      console.log(error);
      setShowEmailModal(false);
    }
  };

  const fetchDetails = async () => {
    // fetchSuppliers();
    try {
      const res = await quotationApis.fetchQuotedQuotationId(qid);
      const { response, status, message } = res;
      if (status) {
        const data = response.data;
        setOriginalData(data.supplierInfo);
        console.log("data", data);
        setRfqStatus(data?.status);
        console.log("rfqStatus", data?.supplierInfo?.supplierHeader.status);

        if (data?.supplierInfo.supplierHeader.additionalChargesArray) {
          setAdditionalCharges(
            data?.supplierInfo.supplierHeader.additionalChargesArray?.map(
              (charge: any) => {
                const id = nanoid(4);
                form2.setFieldsValue({
                  [`AdditionalCharges.${id}.name`]: charge?.name,
                  [`AdditionalCharges.${id}.price`]: Number(charge?.price),
                });
                return {
                  ...charge,
                  id,
                };
              }
            )
          );
        }

        if (data?.supplierInfo?.supplierApprovals) {
          let ids = data?.supplierInfo?.supplierApprovals?.map(
            (item: any) => item?.userId
          );
          ids = ids?.filter((item: any) => item?.status != "Approved");
          setApproverIds(ids);
        }

        if (data?.supplierInfo?.supplierHeader?.dms?.length > 0) {
          setDMS(data?.supplierInfo?.supplierHeader?.dms);
          const _termsAndConditions =
            data?.supplierInfo?.supplierHeader?.dms?.find(
              (item: any) => item.moduleFileType == "termsAndConditions"
            );
          if (_termsAndConditions) {
            const data: any = await fetchHtmlContentFromUrl(
              _termsAndConditions?.filePath
            );
            setTermsAndConditions(data);
            setNewTermsAndConditions(data);
          }
          const _signature = data?.supplierInfo?.supplierHeader?.dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: data?.supplierInfo?.supplierHeader?.signatureLabel,
              isActive: true,
            });
            // setNewInstructions(data);
          }
          const _attachments = data?.supplierInfo?.supplierHeader?.dms?.filter(
            (item: any) => item.moduleFileType == "attachments"
          );
          console.log("_attachments", _attachments);
          if (_attachments) {
            console.log("11::::setAttachments");
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                fileName: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }

        if (data?.supplierInfo?.supplierDocs?.length > 0) {
          const allDocs = data?.supplierInfo?.supplierDocs?.flatMap(
            (doc: any) =>
              doc?.dms?.length > 0
                ? doc.dms.map((dmsItem: any) => ({
                    ...dmsItem,
                    requiredDocs: doc.requiredDocs,
                    quotationDocNameId: doc.quotationDocNameId,
                    hasDms: true, // To indicate it has a dms file
                  }))
                : [
                    {
                      docId: null,
                      fileName: "",
                      filePath: "",
                      requiredDocs: doc.requiredDocs,
                      quotationDocNameId: doc.quotationDocNameId,
                      hasDms: false, // To indicate it's a placeholder for no dms
                    },
                  ]
          );

          console.log("allDocs", allDocs);

          const _recDocAttachments = allDocs.filter(
            (item: any) =>
              item.moduleFileType === "Supplier Docs" ||
              item.moduleFileType == null // Handle no dms case
          );

          console.log("_recDocAttachments", _recDocAttachments);

          if (_recDocAttachments) {
            const transformedAttachments = _recDocAttachments.map(
              (attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName || "", // Handle empty name if no dms
                link: attachment?.filePath || "", // Handle empty link if no dms
                moduleFileType: attachment?.moduleFileType || "Supplier Docs", // Default type if no dms
                requiredDocsName: attachment.requiredDocs,
                quotationDocNameId: attachment.quotationDocNameId,
                isActive: true,
              })
            );

            console.log("DMS:::LOGS:::", transformedAttachments);
            setReqDocAttachments(transformedAttachments);
          }
        }

        setMaterialData(data);
        const LineItems = data?.supplierInfo.supplierLines;
        setData(LineItems);
        setShowSubmitBtm(false);
        setDeletedAttachments([]);
        setNewAttachments([]);
        // setCurrency({
        //     description: currencyCode,
        //     currency_code: currencyCode,
        //     currency_symbol: currencySymbol,
        //     currency_format: currencyFormat,
        //     example: currencyCode,
        // });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadChange = (
    info: any,
    quotationDocNameId: string,
    docId: string
  ) => {
    const { file } = info;

    if (file.status !== "removed") {
      // Append file to newReqAttachment array and include quotationDocNameId and docId
      setNewReqAttachment((prev: any[]) => [
        ...prev,
        {
          file,
          quotationDocNameId,
          docId,
        },
      ]);

      // Show submit button after file is added
      setShowSubmitBtm(true);
    } else {
      // Remove file from newReqAttachment and add docId to deletedDocs
      setNewReqAttachment((prev: any[]) =>
        prev.filter((item) => item.file.uid !== file.uid)
      );
      setDeletedAttachments((prev: any[]) => [...prev, docId]);
    }
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        const costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddresses = async () => {
    try {
      // const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      // if (status) {
      //     const addressOptions = data.map((item: any) => ({
      //         label: item.deliveryLocationName,
      //         value: item.id.toString(),
      //     }));
      //     setBillingAddressList(data);
      //     setBillingAddressOptions(addressOptions);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const edit = (record: any) => {
    console.log("record", record);
    form.setFieldsValue({
      suppliedQty: Number(record.suppliedQty),
      minQty: Number(record.minQty),
      hsn: Number(record.hsn),
      price: Number(record.price),
      sgst: Number(record.sgst),
      cgst: Number(record.cgst),
      igst: Number(record.igst),
      leadDays: Number(record.leadDays),
      ...record,
    });
    console.log("key", form, record.key);
    setEditingKey(record.key);
  };

  const save = async (key: React.Key) => {
    console.log("Save", key);
    try {
      console.log("Try");
      const row: any = await form.validateFields();
      console.log("row", row);
      const newData: any = [...data];
      const index = newData.findIndex((item: any) => key == item.id);

      if (index > -1) {
        const item = newData[index];

        const quantityInvoiced = Number(row.suppliedQty) || 0;
        console.log("quantityInvoiced", quantityInvoiced);
        const unitPrice = Number(row.price) || 0;
        const basicAmount = quantityInvoiced * unitPrice;
        console.log("basicAmount", basicAmount);
        const sgst = Number(row.sgst) || 0;
        const igst = Number(row.igst) || 0;
        const cgst = Number(row.cgst) || 0;

        const sgstData = (basicAmount * sgst) / 100;
        const igstData = (basicAmount * igst) / 100;
        const cgstData = (basicAmount * cgst) / 100;

        // Fix: Ensure that lineTotal is calculated correctly
        const lineTotal = basicAmount + sgstData + igstData + cgstData;

        // Fix: Ensure the HSN value is preserved if it's not editable (i.e., if it's already set)
        const hsnValue = row.hsn || item.hsn;

        const updatedRow = {
          ...item,
          quantityInvoiced,
          unitPrice,
          basicAmount,
          sgst,
          igst,
          cgst,
          sgstData,
          igstData,
          cgstData,
          hsn: hsnValue, // Preserve existing HSN if it's already present
          leadDays: row.leadDays,
          minQty: row.minQty,
          price: row.price,
          suppliedQty: row.suppliedQty,
          grossTotal: basicAmount + sgst + igst + cgst,
          lineTotal: lineTotal || 0, // Ensure a valid total
        };
        console.log("updatedRow", updatedRow);
        newData.splice(index, 1, updatedRow);
        setData(newData);
        setEditingKey(null);
      }
      setShowSubmitBtm(true);
    } catch (errInfo) {
      console.log("Validate Failed:", JSON.stringify(errInfo, null, 2));
    }
  };

  const columns = [
    // {
    //     ...Table.EXPAND_COLUMN,
    //     fixed: "left",
    // },
    // {
    //     title: "MATERIAL ID",
    //     dataIndex: "materialId",
    //     key: "materialId",
    //     fixed: "left" as const,
    //     width: 120,
    // },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left" as const,
      width: 150,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
    },
    {
      title: 'Specification',
      dataIndex: 'specification',
      key: 'specification',
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      width: 150,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text: any, record: any) => {
        const value = Number(text) || 0;
        // Format the number to 2 decimal places and apply Indian locale formatting
        return value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      width: 200,
      render: (text: any, record: any) =>
        `${dayjs(text).format("DD-MMM-YYYY")}`,
    },
    {
      title: "Supply Qty",
      dataIndex: `suppliedQty`,
      key: "suppliedQty",
      width: 120,
      editable: true,
      render: (text: any, record: any) => {
        const value = Number(text) || 0;
        return value.toFixed(2);
      },
    },
    {
      title: "Min Purchase Qty",
      dataIndex: `minQty`,
      key: "minQty",
      width: 150,
      editable: true,
      render: (text: any, record: any) => {
        const value = Number(text) || 0;
        return value.toFixed(2);
      },
    },
    {
      title: "HSN",
      dataIndex: `hsn`,
      key: "hsn",
      width: 120,
      // editable: true,
      editable: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
      editable: true,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>{formatCurrency(text, "INR")}</span>
        </div>
      ),
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      editable: true,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst) ? text : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      editable: true,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {Number(record?.sgst) && Number(record?.cgst) ? text : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "IGST (%)",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      editable: true,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {!Number(record?.sgst) && !Number(record?.cgst) ? text : "N/A"}
          </span>
        </div>
      ),
    },
    {
      title: "Delivery Lead Days",
      dataIndex: "leadDays",
      key: "leadDays",
      width: 250,
      editable: true,
    },
    {
      title: "Line Total",
      dataIndex: "lineTotal",
      key: "lineTotal",
      width: 250,
      // render: (text: any, record: any) => {
      //   const value = Number(text) || 0;
      //   return value.toFixed(2);
      // },
      render: (text: any, record: any) => {
        const value = Number(text) || 0;
        // Format the number to 2 decimal places and apply Indian locale formatting
        return value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        console.log("isEditing:::::0000", editable);
        return editable ? (
          <div className="w-100 justify-content-around">
            <Button
              size="sm"
              color=""
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              <Icon name="save" />
            </Button>

            <Button size="sm" color="" onClick={cancel}>
              <Icon name="cross" />
            </Button>
          </div>
        ) : (
          <Button size="sm" color="" onClick={() => edit(record)}>
            <Icon name="edit" />
          </Button>
        );
      },
    },
  ];

  const cancel = () => {
    setEditingKey(null);
  };

  useEffect(() => {
    if (editLineRow) {
      setAddNewRowModel(true);
    }
  }, [editLineRow]);

  const ApprovalTimelineContent = () => {
    const approvalsData = OriginalData?.supplierApprovals;
    console.log("ApprovalData", OriginalData);
    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };

  const billingAddressContent = OriginalData?.quotationInfo?.quotationHeader
    ?.billingAddressName ? (
    <div className="billingAddress_align">
      <p>
        {
          OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
            .addressLine1
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
            .addressLine2
        }
      </p>
      <p>
        {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName?.city}
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
            ?.districtName
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
            ?.stateName
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
            ?.countryName
        }{" "}
        -{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
            ?.pincode
        }
      </p>
    </div>
  ) : null;

  const orderDeliveryAddressContent = OriginalData?.quotationInfo
    ?.quotationHeader?.deliveryAddressName ? (
    <div className="billingAddress_align">
      <p>
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.addressLine1
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.addressLine2
        }
      </p>
      <p>
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.cityName
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.districtName
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.stateName
        }
        ,{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.countryName
        }{" "}
        -{" "}
        {
          OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.pincode
        }
      </p>
    </div>
  ) : null;

  const returnBillingDetails = () => {
    if (!OriginalData?.quotationInfo?.quotationHeader?.billingAddressName) {
      return {};
    }
    let address =
      OriginalData?.quotationInfo?.quotationHeader?.billingAddressName;
    if (!address) {
      return {};
    }
    return {
      name: OriginalData?.quotationInfo?.quotationHeader?.orgName,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.cityName,
      state: address?.state?.stateName,
      pincode: address.pincode,
      country: address?.countryInfo?.countryName,
      gstin: OriginalData?.quotationInfo?.quotationHeader?.gstin || "",
      pan: OriginalData?.quotationInfo?.quotationHeader?.pan || "",
      cin: OriginalData?.quotationInfo?.quotationHeader?.cin || "",
    };
  };

  const returnSupplierDetails = () => {
    let seller = OriginalData?.supplierHeader?.referencedSupplier;
    if (!seller) {
      return {};
    }
    return {
      name: seller?.referencedSupplierName || "",
      addressLine1: seller?.addressLine1,
      addressLine2: seller?.addressLine2,
      city: seller?.city,
      state: seller?.state,
      pincode: seller?.pincode,
      country: seller?.country,
      gstin: seller?.gstin || "",
      pan: seller?.pan || "",
      cin: seller?.cin || "",
    };
  };
  const returnRFQDetails = () => {
    const id = materialData?.deliveryAddressId;
    let address: any = {};
    if (!id) {
      address = {};
    }
    address = OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName;
    return {
      rfqNumber: OriginalData?.quotationInfo?.quotationHeader?.rfqId,
      rfqDate: dayjs(
        OriginalData?.quotationInfo?.quotationHeader?.createdAt
      ).format("DD-MMM-YYYY"),
      terms:
        materialData?.supplierInfo?.supplierHeader?.paymentTerms +
        (materialData?.supplierInfo?.supplierHeader?.paymentPercentage
          ? `(${Number(
              materialData?.supplierInfo?.supplierHeader?.paymentPercentage
            )?.toFixed(1)}%)`
          : ""),
      expectedShipmentDate:
        OriginalData?.quotationInfo?.quotationHeader?.deliveryDateType ==
        "ORDER_LEVEL"
          ? dayjs(
              OriginalData?.quotationInfo?.quotationHeader?.deliveryDate
            ).format("DD-MMM-YYYY")
          : "Line Level",
      placeofSupply: address?.cityName || "",
      creditDays:
        materialData?.supplierInfo?.supplierHeader?.paymentTerms === "Credit"
          ? materialData?.supplierInfo?.supplierHeader?.paymentDays
          : 0,
      deliveryAddressString:
        [
          address?.addressLine1,
          address?.addressLine2,
          address?.cityName,
          address?.district?.districtName,
          address?.state?.stateName,
          address?.pincode?.toString(),
          address?.countryInfo?.countryName,
        ]
          ?.filter(Boolean)
          ?.join(", ") || "--",
      totals: {
        taxableAmount: formatDecimal(
          materialData?.supplierInfo?.supplierHeader?.netTotal
        ),
        cgst: formatDecimal(materialData?.supplierInfo?.supplierHeader?.cgst),
        sgst: formatDecimal(materialData?.supplierInfo?.supplierHeader?.sgst),
        igst: formatDecimal(materialData?.supplierInfo?.supplierHeader?.igst),
        total: formatDecimal(materialData?.supplierInfo?.supplierHeader?.gross),
      },
      lines: materialData?.supplierInfo?.supplierLines?.map(
        (item: any, index: any) => {
          let itemDescription = item?.materialName;
          if (item?.description) {
            itemDescription += "\t" + item?.description;
          }
          if (
            OriginalData?.quotationInfo?.quotationHeader?.deliveryDateType !=
            "ORDER_LEVEL"
          ) {
            // let _address = deliveryAddressList?.find(
            //   (_item: any) => _item?.id == item?.deliveryAddressId
            // );
            // if (_address) {
            //   const addressString = [
            //     _address.addressLine1,
            //     _address.addressLine2,
            //     _address.city,
            //     _address.stateName,
            //     _address.pincode?.toString(),
            //     _address.countryName,
            //   ]
            //     .filter(Boolean)
            //     .join(", ");
            //   itemDescription += "\n\nDelivery Address: " + addressString;
            // }
          }

          return {
            sno: index + 1,
            itemDescription,
            hsnSac: item?.hsn || "",
            rate: Number(item?.price),
            cgstPercentage: Number(item?.cgst),
            cgstAmount: Number(item?.cgstAmt),
            sgstPercentage: Number(item?.sgst),
            sgstAmount: Number(item?.sgstAmt),
            igstPercentage: Number(item?.igst),
            igstAmount: Number(item?.igstAmt),
            taxableAmount: Number(item?.lineTotal),
            qty: Number(item?.quantity),
            uom: item?.uomName,
          };
        }
      ),
    };
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      // Perform any file validation or processing here
      setNewRDCAttachment(file);
      setShowSubmitBtm(true);
      return false; // Return false to prevent auto-upload
    },
    maxCount: 1,
    onRemove: () => {
      setNewRDCAttachment(null); // Clear the file from state
    },
  };

  const SubmitChanges = async () => {
    console.log("newReqDoceAttachment", newReqAttachment);
    // return
    try {
      const _formData = new FormData();
      const _updatedLineItems: any = [];
      const updatedLineItems: any = [];
      const newLineItems: any = [];
      const deletedLineItems: any = [];
      // const deletedFileData: any = [];

      const newfiledata: any = [];
      const deletedFileData: any = [...deletedAttachments];
      const updatedFileData: any = [];

      data?.forEach((item: any) => {
        if (!item?.quotationLineId) {
          // new item
          newLineItems.push(item);
        }
      });

      newAttachments.forEach((attachment: any, index: number) => {
        if (!attachment) {
          return;
        }
        console.log("attachment", attachment);
        const file = attachment?.originFileObj;
        _formData.append("files", file);
        newfiledata.push({
          fileIndex: newfiledata.length,
          module: "Quotations",
          moduleFileType: "attachments",
          level: "HEADER_LEVEL",
        });
      });

      if (isTermsAndConditionsChanged) {
        const termsAndConditionsFile = new Blob([newTermsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        newfiledata.push({
          fileIndex: newfiledata.length,
          module: "quotations",
          moduleFileType: "termsAndConditions",
          level: "HEADER_LEVEL",
        });
        setIsTermsAndConditionsChanged(false);
        const _termsAndConditions = DMS?.find(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions?.docId) {
          deletedFileData.push({
            docId: _termsAndConditions.docId,
          });
        }
      }

      newReqAttachment.forEach((file: any) => {
        const requiredDocsName = fileNameMapping.get(file.name);
        newfiledata.push({
          fileIndex: newfiledata.length,
          module: "Quotations",
          moduleFileType: "Supplier Docs",
          level: "LINE_LEVEL",
          requiredDocsName: requiredDocsName,
          quotationDocNameId: file.quotationDocNameId,
        });
        if (file.docId) {
          deletedAttachments.push({
            docId: file.docId,
          });
        }
        _formData.append(
          "files",
          new File([file.file], file.file.name, { type: file.file.type })
        );
      });

      // const newAttachmentNames = newReqAttachment.map(file => file.name);
      // reqDocAttachments.forEach((attachment:any) => {
      //     if (!newAttachmentNames.includes(attachment.name)) {
      //         deletedFileData.push({
      //             docId: attachment.docId,
      //         });
      //     }
      // });

      let delDocID: any = [];
      const deletedDocIds = deletedAttachments.map((deleted: any) =>
        delDocID.push({
          docId: deleted.docId,
        })
      );
      console.log("delDocID", delDocID);

      const filesData = {
        newfiledata,
        deletedFileData: delDocID,
        updatedFileData,
      };

      console.log("original data", OriginalData);

      if (additionalCharges) {
        OriginalData.supplierHeader.additionalChargesArray =
          additionalCharges?.map((charge: any) => {
            return {
              name: charge?.name,
              price: charge?.price,
            };
          });
      }

      if (newNegotiableType != "") {
        OriginalData.supplierHeader.negotiable = newNegotiableType;
        setNewNegotiableType("");
      }

      if (newValidityDate != "") {
        OriginalData.supplierHeader.validityDate =
          dayjs(newValidityDate).format("YYYY-MM-DD");
        setNewValidityDate("");
      }

      if (newPaymentType != "") {
        OriginalData.supplierHeader.paymentTerms = newPaymentType;
        setNewPaymentType("");
      }

      if (newPaymentDays != "") {
        OriginalData.supplierHeader.paymentDays = newPaymentDays;
        setNewPaymentDays("");
      }

      if (newPaymentPercentage != "") {
        OriginalData.supplierHeader.paymentPercentage = newPaymentPercentage;
        setNewPaymentPercentage("");
      }

      if (newLineItems) {
        OriginalData.supplierLines = data;
      }

      OriginalData.supplierLines.forEach((item: any) => {
        item.needByDate = dayjs(item.needByDate).format("YYYY-MM-DD");
      });

      _formData.append("filesData", JSON.stringify(filesData));

      // _formData.append("files", JSON.stringify([]));

      _formData.append(
        "supplierHeader",
        JSON.stringify(OriginalData.supplierHeader)
      );

      _formData.append(
        "supplierLines",
        JSON.stringify(OriginalData.supplierLines)
      );

      _formData.append(
        "supplierApprovals",
        JSON.stringify(OriginalData.supplierApprovals)
      );

      // return

      const res = await quotationApis.quoteNewVersion(_formData);
      const { status, message }: any = res;
      if (status === true) {
        fetchDetails();
        Swal.fire("Updated!", message, "success");
        navigate("/quoted_view");
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableSubmit(false);
  };

  const mergedColumns: any = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const regretSupplier = async () => {
    console.log("regretSupplier", OriginalData);
    try {
      const res = await quotationApis.regretSupplier(
        OriginalData?.supplierHeader?.quotationId,
        OriginalData?.supplierHeader?.referencedSupplier?.referencedSupplierId
      );
      const { status, message, response }: any = res;
      if (status === true) {
        Swal.fire("Regretted!", message, "success");
        navigate("/quoted_view");
      } else {
        Swal.fire("Error!", response, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableRegret(false);
  };

  return (
    <>
      <Head title="View Quotation Details" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        <span>
                          RFQ - {OriginalData?.supplierHeader?.rfqId} |{" "}
                          {(statusMapper[rfqStatus] &&
                            statusMapper[rfqStatus]?.buyer?.title) ||
                            rfqStatus}{" "}
                        </span>
                      </BlockTitle>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {showSubmitBtm &&
                        ![
                          "Converted",
                          "Under Evaluation",
                          "Partial Converted",
                          "Regret from Supplier",
                          "Rejected",
                        ].includes(rfqStatus) && (
                          <div className="actions clearfix">
                            <ul
                              className="m-auto d-flex align-items-center justify-content-center p-0"
                              style={{
                                flexDirection: "row-reverse",
                              }}
                            >
                              <li className="pt-0 pb-0">
                                {disableSubmit ? (
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                      setDisableSubmit(true);
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "Do you want to submit the changes?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, submit it!",
                                        cancelButtonText: "No, cancel",
                                      }).then(async (result) => {
                                        if (result.isConfirmed) {
                                          await SubmitChanges();
                                        }
                                      });
                                    }}
                                  >
                                    Submit
                                  </Button>
                                )}
                              </li>
                              <li className="pt-0 pb-0">
                                <Button
                                  color="secondary"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to revert the changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, revert it!",
                                      cancelButtonText: "No, cancel",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        fetchDetails();
                                        Swal.fire(
                                          "Reverted!",
                                          "Your changes have been reverted.",
                                          "success"
                                        );
                                      }
                                    });
                                  }}
                                  type="button"
                                  className="btn-dim btn btn-secondary"
                                >
                                  Revert
                                </Button>
                              </li>
                            </ul>
                          </div>
                        )}

                      {!showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {![
                                "Converted",
                                "Under Evaluation",
                                "Partial Converted",
                                "Regret from Supplier",
                                "Rejected",
                              ].includes(rfqStatus) && (
                                <>
                                  {disableRegret ? (
                                    <Button color="primary" type="button">
                                      <Spinner size="sm" color="light" />
                                    </Button>
                                  ) : (
                                    <Button
                                      color="secondary"
                                      onClick={() => {
                                        setDisableRegret(true);
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "Do you want to Regretted?",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, Regret!",
                                          cancelButtonText: "No, cancel",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            regretSupplier();
                                            // Swal.fire(
                                            //     "Regretted!",
                                            //     "Your changes have been reverted.",
                                            //     "success"
                                            // );
                                          }
                                        });
                                      }}
                                      type="button"
                                      className="btn-dim btn btn-secondary"
                                    >
                                      Regret
                                    </Button>
                                  )}
                                </>
                              )}
                            </li>

                            <li className="pt-0 pb-0">
                              <PdfGenerator
                                orgDetails1={returnSupplierDetails()}
                                orgDetails2={returnBillingDetails()}
                                rfqDetails={returnRFQDetails()}
                                buyerOrgLogo={currentUser?.orgLogo}
                                hasIGST={
                                  Number(
                                    materialData?.supplierInfo?.supplierHeader
                                      ?.igst
                                  ) != 0
                                }
                                getPdfUrl={updatePdfBlobDataRef}
                                TermsAndConditions={termsAndConditions}
                              />
                            </li>
                            <li className="pt-0 pb-0 p-2">
                              <Dropdown
                                isOpen={shareOptionsToggle}
                                toggle={() => {
                                  setShareOptionsToggle(!shareOptionsToggle);
                                }}
                              >
                                <DropdownToggle className="btn btn-white btn-outline-light">
                                  <Icon name="share"></Icon>
                                  <span>Send</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <DropdownItem onClick={() => {}}>
                                    <Icon name="archive"></Icon>
                                    <span>Send SMS</span>
                                  </DropdownItem> */}
                                  <DropdownItem
                                    onClick={() => {
                                      setShowEmailModal(true);
                                    }}
                                  >
                                    <Icon name="mail"></Icon>
                                    <span>Send Mail</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>

                            <li className="pt-0 pb-0">
                              <Button
                                className="toggle"
                                color="primary"
                                onClick={async () => {
                                  navigate("/quoted_view");
                                }}
                              >
                                <Icon name="arrow-left" />
                                <span>Back</span>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1"]}>
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <span>General Details</span>
                        </div>
                      }
                      key="1"
                    >
                      <Descriptions
                        title=""
                        className="custom-description-title"
                      >
                        <Descriptions.Item label="Quotation For">
                          {
                            materialData?.supplierInfo?.quotationInfo
                              ?.quotationHeader?.quotationFor
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="Description">
                          {
                            materialData?.supplierInfo?.quotationInfo
                              ?.quotationHeader?.quotationDescription
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="Preferred Brands / Make">
                                {OriginalData?.quotationInfo?.quotationHeader
                                  ?.preferredBrands || "NA"}
                              </Descriptions.Item>
                        <Descriptions.Item label="Site Name">
                          {
                            materialData?.supplierInfo?.quotationInfo
                              ?.quotationHeader?.siteName
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="Additional Charges">
                          {
                            materialData?.supplierInfo?.quotationInfo
                              ?.quotationHeader?.additionalCharges
                          }
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Last Submission Date">
                                                    <span className="badge-dim badge bg-primary rounded-pill">
                                                        <strong> {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.lastSubmissionDate && dayjs(OriginalData?.quotationHeader?.lastSubmissionDate).format("DD-MMM-YYYY")}
                                                        </strong>
                                                    </span>
                                                </Descriptions.Item> */}
                        {/* <Descriptions.Item label="Time Limit">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deadlineType}
                                                </Descriptions.Item> */}
                        {/* <Descriptions.Item label="Site Name">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.siteName}
                                                </Descriptions.Item> */}
                        <Descriptions.Item label="Approvals">
                          {<ApprovalTimelineContent />}
                        </Descriptions.Item>
                        <Descriptions.Item label="Negotiable">
                          {/* {" "}
                                                    {materialData?.supplierInfo?.supplierHeader?.negotiable == 'Y' ? "Yes" : "No"} */}
                          {[
                            "Converted",
                            "Under Evaluation",
                            "Partial Converted",
                            "Regret from Supplier",
                            "Rejected",
                          ].includes(rfqStatus) ? (
                            <>
                              {materialData?.supplierInfo?.supplierHeader
                                ?.negotiable == "Y"
                                ? "Yes"
                                : "No"}
                            </>
                          ) : (
                            <>
                              <Form.Item
                                name="negotiable"
                                label={undefined}
                                className="w-50 paddingLeft10  p-0 m-0"
                                initialValue={
                                  materialData?.supplierInfo?.supplierHeader
                                    ?.negotiable
                                }
                              >
                                <Select
                                  placeholder="Select Negotiable Type"
                                  defaultValue={
                                    materialData?.supplierInfo?.supplierHeader
                                      ?.negotiable
                                  }
                                  onChange={(e) => {
                                    console.log("e", e);
                                    setNewNegotiableType(e);
                                    setShowSubmitBtm(true);
                                  }}
                                >
                                  <Option value="Y">Yes</Option>
                                  <Option value="N">No</Option>
                                </Select>
                              </Form.Item>
                            </>
                          )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Validity Date">
                          {/* {dayjs(materialData?.supplierInfo?.supplierHeader?.validityDate).format("DD-MM-YYYY")} */}
                          {[
                            "Converted",
                            "Under Evaluation",
                            "Partial Converted",
                            "Regret from Supplier",
                            "Rejected",
                          ].includes(rfqStatus) ? (
                            <>
                              {dayjs(
                                materialData?.supplierInfo?.supplierHeader
                                  ?.validityDate
                              ).format("DD-MM-YYYY")}
                            </>
                          ) : (
                            <>
                              <Form.Item
                                name="validityDate"
                                label={undefined}
                                className="w-50 paddingLeft10  p-0 m-0"
                                initialValue={
                                  materialData?.supplierInfo?.supplierHeader
                                    ?.validityDate
                                }
                              >
                                <DatePicker
                                  className="w-100"
                                  placeholder="Invoice Due Date"
                                  minDate={dayjs().add(0, "day")}
                                  allowClear={false}
                                  defaultValue={dayjs(
                                    materialData?.supplierInfo?.supplierHeader
                                      ?.validityDate
                                  )}
                                  onChange={(e) => {
                                    setNewValidityDate(e);
                                    setShowSubmitBtm(true);
                                  }}
                                />
                              </Form.Item>
                            </>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Payment Terms">
                          {
                            materialData?.supplierInfo?.supplierHeader
                              ?.paymentTerms
                          }
                        </Descriptions.Item>
                        {((materialData?.supplierInfo?.supplierHeader
                          ?.paymentTerms === "Credit" &&
                          newPaymentType == "") ||
                          newPaymentType === "Credit") && (
                          // <Descriptions.Item label="Payment Days">
                          //     {materialData?.supplierInfo?.supplierHeader?.paymentDays || "--"}
                          // </Descriptions.Item>
                          <>
                          <Descriptions.Item label="Credit Days">
                            {
                              materialData?.supplierInfo?.quotationInfo?.quotationHeader
                                ?.expectedCreditDays
                            }
                          </Descriptions.Item>
                           <Descriptions.Item label="Supplier Credit Days">
                           {[
                            "Converted",
                            "Under Evaluation",
                            "Partial Converted",
                            "Regret from Supplier",
                            "Rejected",
                          ].includes(rfqStatus) ? (
                            <>
                             {
                             materialData?.supplierInfo?.supplierHeader
                               ?.paymentDays
                           }
                            </>
                          ) : (
                            <Form.Item
                            name="paymentDays"
                            // label={
                            //   <div className="data-label w-100 mb-1">
                            //     Supplier Credit Days
                            //   </div>
                            // }
                            // defaultValue={
                            //   materialData?.supplierInfo?.supplierHeader
                            //     ?.paymentDays
                            // }
                            className="w-50 p-2 paddingLeft10  pt-0 pb-0"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Credit Days",
                              },
                            ]}
                          >
                            <Input
                              className="w-100"
                              type="number"
                              defaultValue={
                                materialData?.supplierInfo?.supplierHeader
                                  ?.paymentDays}
                              placeholder="Enter  Supplier Credit Days"
                              onChange={(e) => {
                                setNewPaymentDays(e.target.value);
                                setShowSubmitBtm(true);
                              }}
                            />
                          </Form.Item>
                              
                          )}
                         </Descriptions.Item>
                         </>
                        )}

                        {((materialData?.supplierInfo?.supplierHeader
                          ?.paymentTerms == "Advance" &&
                          newPaymentType == "") ||
                          newPaymentType === "Advance") && (
                          <Descriptions.Item label="Payment %">
                            {
                              materialData?.supplierInfo?.supplierHeader
                                ?.paymentPercentage
                            }
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Billing Address">
                          <Popover
                            content={billingAddressContent}
                            title={
                              <span style={{ color: "#2263b3" }}>
                                Billing Address
                              </span>
                            }
                          >
                            <span
                              style={{
                                color: "#2263b3",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {/* Address */}
                              {
                                materialData?.supplierInfo?.quotationInfo
                                  ?.quotationHeader?.billingAddressName
                                  ?.deliveryLocationName
                              }
                              {/* {
                                                            OriginalData?.quotationHeader?.billingAddressName
                                                        } */}
                            </span>
                          </Popover>
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Delivery Address">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deliveryLevelType === "LINE_LEVEL"
                                                        ? "Line Level"
                                                        : "Order Level"}
                                                </Descriptions.Item> */}

                        {materialData?.supplierInfo?.quotationInfo
                          ?.quotationHeader?.deliveryLevelType ===
                          "ORDER_LEVEL" && (
                          <Descriptions.Item label="Delivery Address">
                            <Popover
                              content={orderDeliveryAddressContent}
                              title={
                                <span style={{ color: "#2263b3" }}>
                                  Delivery Address
                                </span>
                              }
                            >
                              <span
                                style={{
                                  color: "#2263b3",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {
                                  materialData?.supplierInfo?.quotationInfo
                                    ?.quotationHeader?.deliveryAddressName
                                    ?.deliveryLocationName
                                }
                              </span>
                            </Popover>
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    </Panel>
                    <Panel header="Materials" key="2">
                      <div className="table-responsive poTable">
                        <div
                          style={{
                            width: screenSize - 120 + "px",
                            margin: "0px auto",
                          }}
                        >
                          <Form form={form} component={false}>
                            <Table
                              // columns={columns}
                              columns={mergedColumns.map((col: any) => {
                                console.log("col", col.dataIndex);
                                if (
                                  [
                                    "Converted",
                                    "Under Evaluation",
                                    "Partial Converted",
                                    "Regret from Supplier",
                                    "Rejected",
                                  ].includes(rfqStatus) &&
                                  col.dataIndex === "actions"
                                ) {
                                  // Return the column with hidden set to true
                                  return {
                                    ...col,
                                    hidden: true,
                                  };
                                }
                                // Return the column as is
                                return col;
                              })}
                              dataSource={data.map((item: any) => ({
                                ...item,
                                key: item.quotationLineId,
                              }))}
                              scroll={{ x: 1000 }}
                              components={{
                                body: {
                                  cell: EditableCell,
                                },
                              }}
                              bordered
                              size="middle"
                              pagination={false}
                            />
                          </Form>
                        </div>
                        <div className="d-flex">
                          <Form form={form2} component={false}>
                            <Row className="mt-2 w-50">
                              <div>
                                {materialData?.supplierInfo?.quotationInfo
                                  ?.quotationHeader?.additionalCharges !==
                                  "Fixed" && (
                                  <div className="d-flex align-items-center">
                                    <h6 className="m-1 mb-0">
                                      Additional Charges (If Any)
                                    </h6>
                                    {![
                                      "Converted",
                                      "Under Evaluation",
                                      "Partial Converted",
                                      "Regret from Supplier",
                                      "Rejected",
                                    ].includes(rfqStatus) && (
                                      <div className="w-20 m-1 mb-0">
                                        {editAdditionalCharges ? (
                                          <a
                                            type={undefined}
                                            onClick={() => {
                                              if (
                                                additionalCharges.find(
                                                  (field: any, ind: number) => {
                                                    const name =
                                                      form2.getFieldValue(
                                                        `AdditionalCharges.${field?.id}.name`
                                                      );
                                                    const price =
                                                      form2.getFieldValue(
                                                        `AdditionalCharges.${field?.id}.price`
                                                      );
                                                    return !name || !price;
                                                  }
                                                )
                                              ) {
                                                return;
                                              }

                                              setAdditionalCharges([
                                                ...additionalCharges,
                                                {
                                                  name: "",
                                                  price: null,
                                                  id: nanoid(4),
                                                },
                                              ]);
                                            }}
                                            className="addNewItem"
                                          >
                                            <PlusSquareOutlined />
                                          </a>
                                        ) : (
                                          <a
                                            type={undefined}
                                            onClick={() => {
                                              setEditAdditionalCharges(true);
                                            }}
                                            className="addNewItem"
                                          >
                                            <EditOutlined />
                                          </a>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {additionalCharges?.map(
                                  (field: any, index: number) => {
                                    return (
                                      <Row
                                        key={field.id}
                                        className="m-2 mb-0 w-100"
                                      >
                                        <div className="w-50">
                                          {editAdditionalCharges ? (
                                            <Form.Item
                                              name={`AdditionalCharges.${field.id}.name`}
                                              label={
                                                <strong>
                                                  Additional Charge Name
                                                </strong>
                                              }
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Charge Name is required",
                                                },
                                              ]}
                                              className="mb-0"
                                              initialValue={field.name}
                                            >
                                              <Input
                                                className="w-100"
                                                disabled={
                                                  !editAdditionalCharges
                                                }
                                                autoComplete="off"
                                                defaultValue={field.name}
                                              />
                                            </Form.Item>
                                          ) : (
                                            <strong>{field.name}</strong>
                                          )}
                                        </div>
                                        <div className="w-30">
                                          {editAdditionalCharges ? (
                                            <Form.Item
                                              name={`AdditionalCharges.${field.id}.price`}
                                              label={<strong>Price</strong>}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Price is required",
                                                },
                                              ]}
                                              className="mb-0"
                                              initialValue={Number(field.price)}
                                            >
                                              <InputNumber
                                                min={0}
                                                disabled={
                                                  !editAdditionalCharges
                                                }
                                                defaultValue={Number(
                                                  field.price
                                                )}
                                                className="w-100"
                                                formatter={(value) =>
                                                  `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )
                                                }
                                                parser={(value: any) =>
                                                  value!.replace(
                                                    /\$\s?|(,*)/g,
                                                    ""
                                                  )
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/^[0-9]+$/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                autoComplete="off"
                                              />
                                            </Form.Item>
                                          ) : (
                                            <strong>
                                              {formatCurrency(
                                                field.price,
                                                "INR"
                                              )}
                                            </strong>
                                          )}
                                        </div>
                                        <Col className="d-flex align-items-end w-15">
                                          {editAdditionalCharges && (
                                            <a
                                              href="#dropdownitem"
                                              onClick={() => {
                                                form2.setFieldsValue({
                                                  [`AdditionalCharges.${field.id}.name`]:
                                                    "",
                                                  [`AdditionalCharges.${field.id}.price`]:
                                                    null,
                                                });
                                                const newAdditionalCharges: any =
                                                  [];
                                                additionalCharges.forEach(
                                                  (fld: any, ind: number) => {
                                                    if (fld.id != field.id) {
                                                      const name =
                                                        form2.getFieldValue(
                                                          `AdditionalCharges.${fld.id}.name`
                                                        );
                                                      const price =
                                                        form2.getFieldValue(
                                                          `AdditionalCharges.${fld.id}.price`
                                                        );
                                                      if (name && price) {
                                                        newAdditionalCharges.push(
                                                          {
                                                            name,
                                                            price,
                                                            id: fld.id,
                                                          }
                                                        );
                                                      }
                                                    }
                                                  }
                                                );
                                                setAdditionalCharges(
                                                  newAdditionalCharges
                                                );
                                                setShowSubmitBtm(true);
                                              }}
                                              className="addNewItem"
                                            >
                                              <DeleteOutlined />
                                            </a>
                                          )}
                                        </Col>
                                      </Row>
                                    );
                                  }
                                )}
                                {additionalCharges?.length > 0 &&
                                  editAdditionalCharges && (
                                    <Button
                                      className="m-4 mt-3 mb-0"
                                      color="primary"
                                      onClick={() => {
                                        const newAdditionalCharges: any = [];
                                        additionalCharges.forEach(
                                          (field: any, index: number) => {
                                            const name = form2.getFieldValue(
                                              `AdditionalCharges.${field?.id}.name`
                                            );
                                            const price = form2.getFieldValue(
                                              `AdditionalCharges.${field?.id}.price`
                                            );
                                            console.log(field?.id, name, price);

                                            if (name && price) {
                                              newAdditionalCharges.push({
                                                name,
                                                price,
                                                id: field?.id,
                                              });
                                            }
                                          }
                                        );
                                        setAdditionalCharges(() => {
                                          return newAdditionalCharges;
                                        });
                                        if (newAdditionalCharges?.length != 0) {
                                          setEditAdditionalCharges(false);
                                        }

                                        setShowSubmitBtm(true);
                                      }}
                                    >
                                      Save
                                    </Button>
                                  )}
                              </div>
                            </Row>
                          </Form>

                          <ViewTableTotals
                            data={data}
                            additionalCharges={additionalCharges}
                            invoice={true}
                          />
                        </div>
                        <Row className="m-4 justify-content-end ">
                          {signature?.isActive && (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                              </div>
                              <div
                                style={{
                                  minHeight: signature?.image ? "0px" : "120px",
                                  display: signature?.image ? "block" : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {signature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={signature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>

                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {}}
                                          disabled
                                          defaultValue={
                                            signature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Other Information" key="3">
                      <Descriptions
                        title=""
                        className="custom-description-title"
                      >
                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Required Documents &nbsp;
                            </strong>
                            {reqDocAttachments.map((docs: any) => (
                              <div
                                key={docs.docId || docs.quotationDocNameId}
                                className="w-auto m-3 mt-2 mb-0"
                              >
                                <div>
                                  {/* Display the requiredDocs name */}
                                  <strong>
                                    {docs.requiredDocsName} &nbsp; &nbsp;
                                  </strong>
                                  <Upload
                                    {...uploadProps}
                                    id={docs.docId || docs.quotationDocNameId}
                                    onChange={(info: any) =>
                                      handleUploadChange(
                                        info,
                                        docs.quotationDocNameId,
                                        docs.docId
                                      )
                                    }
                                  >
                                    <CloudUploadOutlined />
                                  </Upload>
                                  {/* Conditionally display the existing file link if it exists */}
                                  {docs.link &&
                                    !newReqAttachment.some(
                                      (file: any) => file.name === docs.name
                                    ) && (
                                      <a
                                        href={docs?.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {docs?.name}
                                      </a>
                                    )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Terms and Conditions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setTermsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>

                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Attachments &nbsp;
                              <PaperClipOutlined />
                            </strong>
                            <div>
                              {attachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>{index + 1}.</strong>
                                      &nbsp;
                                      {attachment.fileName}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                      &nbsp; &nbsp;
                                      <a
                                        style={{
                                          color: "red",
                                          fontSize: "16px",
                                        }}
                                        onClick={() => {
                                          setDeletedAttachments((prev: any) => [
                                            ...prev,
                                            { docId: attachment?.docId },
                                          ]);
                                          let updatedAttachmentsList =
                                            attachments?.filter(
                                              (item: any, i: number) =>
                                                i != index
                                            );
                                          setAttachments(
                                            updatedAttachmentsList
                                          );
                                          setShowSubmitBtm(true);
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                              {newAttachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>
                                        {attachments?.length + index + 1}.
                                      </strong>
                                      &nbsp;
                                      {attachment?.name}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                      &nbsp; &nbsp;
                                      <a
                                        style={{
                                          color: "red",
                                          fontSize: "16px",
                                        }}
                                        onClick={() => {
                                          setNewAttachments((prev: any) =>
                                            prev.filter(
                                              (item: any, i: number) =>
                                                i != index
                                            )
                                          );
                                          setShowSubmitBtm(true);
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                              {![
                                "Converted",
                                "Under Evaluation",
                                "Partial Converted",
                                "Regret from Supplier",
                                "Rejected",
                              ].includes(rfqStatus) && (
                                <div className="mt-1">
                                  <Dragger
                                    beforeUpload={beforeUpload}
                                    fileList={[]}
                                    onChange={handleAttachmentChange}
                                    accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.xlsx,.html,.hbs"
                                    showUploadList={{ showPreviewIcon: false }}
                                    itemRender={(
                                      originNode,
                                      file,
                                      fileList,
                                      actions
                                    ) => {
                                      // Customize the rendering of each file to remove the tooltip
                                      return React.cloneElement(originNode, {
                                        title: null, // Removes the tooltip entirely
                                      });
                                    }}
                                  >
                                    <a
                                      onClick={() => {}}
                                      className="addNewItem"
                                    >
                                      <Icon name="plus-round" />
                                      <span>Add Attachment</span>
                                    </a>
                                  </Dragger>
                                </div>
                              )}
                            </div>
                          </div>
                        </Descriptions.Item>

                        {/* {suppliers &&
                                                    <Descriptions.Item label="Suppliers">
                                                        {suppliers.map((item) => (
                                                            <li>
                                                                {item.supplierName}
                                                            </li>
                                                        )
                                                        )}
                                                    </Descriptions.Item>
                                                } */}
                      </Descriptions>
                    </Panel>
                  </AntCollapse>
                </div>
                {showEmailModal && (
                  <EmailCreationModal
                    modal={showEmailModal}
                    toggle={() => setShowEmailModal(!showEmailModal)}
                    sendEmail={TriggerEmail}
                    body={
                      <div className="w-100 p-2">
                        <img
                          src={currentUser?.orgLogo}
                          alt={currentUser?.orgName}
                          width="200"
                          style={{ marginTop: "20px" }}
                        />
                        <div
                          style={{ backgroundColor: "#fff", padding: "15px" }}
                        >
                          <h2 style={{ color: "#2263b3" }}>
                            Quotation for Your Request
                          </h2>
                          <p style={{ color: "#1ee0ac", fontSize: "20px" }}>
                            Dear{" "}
                            {
                              OriginalData?.quotationInfo?.quotationHeader
                                ?.orgName
                            }
                            ,
                          </p>
                          <p>
                            We are pleased to provide you with a quotation based
                            on your recent request. Please find the attached PDF
                            document detailing the quotation, including item
                            descriptions, quantities, and pricing.
                          </p>

                          <p>
                            <strong>Quotation Summary:</strong>
                          </p>
                          <p>
                            <strong>Quotation Number:</strong>{" "}
                            {
                              OriginalData?.quotationInfo?.quotationHeader
                                ?.rfqId
                            }
                            <br />
                            <strong>Date Issued:</strong>{" "}
                            {dayjs(
                              OriginalData?.quotationInfo?.quotationHeader
                                ?.createdAt
                            ).format("DD-MMM-YYYY")}
                            <br />
                            <strong>Valid Until:</strong>{" "}
                            {dayjs(
                              materialData?.supplierInfo?.supplierHeader
                                ?.validityDate
                            ).format("DD-MMM-YYYY")}
                            <br />
                            <strong>Total Amount:</strong>{" "}
                            {formatCurrency(
                              Number(OriginalData?.supplierHeader?.gross),
                              "INR"
                            )}
                            <br />
                          </p>
                          <p>
                            We kindly request you to review the quotation and
                            let us know if there are any questions or further
                            requirements. Should you wish to proceed with this
                            quotation, please confirm at your earliest
                            convenience.
                          </p>

                          <p>
                            For assistance, feel free to reach out to us at{" "}
                            <a href={`mailto:{${currentUser?.email}}}`}>
                              {currentUser?.email}
                            </a>
                            .
                          </p>
                          <p>
                            This is an automatically generated email. Please do
                            not reply directly to this email.
                          </p>
                        </div>
                      </div>
                    }
                    defaultAttachments={Object.assign({}, pdfBlobData.current, {
                      name: OriginalData?.supplierHeader?.rfqId + ".pdf",
                      type: "application/pdf",
                    })}
                  />
                )}

                {addNewRowModel && (
                  <MaterialModal
                    modal={addNewRowModel}
                    closeModal={() => {
                      setAddNewRowModel(false);
                      setEditLineRow(null);
                    }}
                    status={rfqStatus}
                    formData={{
                      action: editLineRow == null ? "add" : "edit",
                      index: editLineRow?.quotationLineId,
                      item: editLineRow,
                    }}
                    append={(data: any) => {
                      console.log("append");
                      let newLineItems =
                        materialData?.supplierInfo?.supplierLines;
                      const _data = newLineItems.find((item: any) => {
                        if (
                          item.materialId == data.materialId
                          // &&
                          // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                          // dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                          // item.deliveryAddressId == data.deliveryAddressId
                        )
                          return true;
                      });

                      if (_data) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "Material Already Added",
                        });
                        return;
                      }

                      setMaterialData((pre: any) => {
                        let newData = { ...pre };
                        newData?.quotationLines?.push(data);
                        console.log("addNEW::DATA", newData);
                        return newData;
                      });
                      setData((pre: any) => {
                        return [...pre, data];
                      });
                      setShowSubmitBtm(true);
                    }}
                    update={(id: any, data: any) => {
                      console.log("update", data, id);
                      let newLineItems =
                        materialData?.supplierInfo?.supplierLines;
                      console.log("newLineItems", newLineItems);
                      const _data = newLineItems
                        .filter((item: any) => item.quotationLineId != id)
                        .find((item: any) => {
                          if (
                            item.materialId == data.materialId
                            // &&
                            // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                            // dayjs(data.needByDate).format(
                            //     "DD-MMM-YYYY"
                            // ) &&
                            // item.deliveryAddressId == data.deliveryAddressId
                          )
                            return true;
                        });
                      console.log("_dataaa", _data);
                      if (_data) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: " Material Already Added",
                        });
                        return;
                      }

                      newLineItems = newLineItems.map((item: any) => {
                        if (item.quotationLineId == id) {
                          return data;
                        }
                        return item;
                      });
                      console.log("newLineItems", newLineItems);
                      setMaterialData((pre: any) => {
                        let newData = { ...pre, quotationLines: newLineItems };
                        console.log("newData", newData);
                        return newData;
                      });
                      setData(newLineItems);
                      setShowSubmitBtm(true);
                    }}
                    // needByDateType={materialData?.needByDateType}
                    // deliveryAddressType={materialData?.deliveryAddressType}
                  />
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      <AntModal
        title={undefined}
        visible={instructionsVisible}
        footer={null}
        onCancel={() => {
          setIsInstructionsChanged(false);
          setInstructionsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Instructions </h5>
          {!isInstructionsChanged &&
            ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
              <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
            )}
        </div>
        {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) &&
        isInstructionsChanged ? (
          // <ReactQuill
          //   theme="snow"
          //   value={newInstructions}
          //   onChange={(data) => {
          //     setNewInstructions(data);
          //   }}
          //   className="mb-3"
          // />
          <FormProvider {...methods}>
            <form>
              <div className="mt-1 mb-3">
                <FormTextArea
                  name="instructions"
                  label=""
                  placeholder="Enter Instructions"
                  required
                  rows={10}
                  defaultValue={newInstructions}
                />
              </div>
            </form>
          </FormProvider>
        ) : instructions ? (
          <div
            dangerouslySetInnerHTML={{
              __html: instructions || "",
            }}
            className="m-3"
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Instructions Available for this Quotation" />
          </div>
        )}
        {isInstructionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setInstructionsVisible(false);
                  setIsInstructionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsInstructionsChanged(false);
                  setNewInstructions(instructions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
      <AntModal
        title={undefined}
        visible={termsVisible}
        footer={null}
        onCancel={() => {
          setIsTermsAndConditionsChanged(false);
          setTermsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Terms and Conditions</h5>
          {!isTermsAndConditionsChanged &&
            ![
              "Converted",
              "Under Evaluation",
              "Partial Converted",
              "Regret from Supplier",
              "Rejected",
            ].includes(rfqStatus) && (
              <EditOutlined
                onClick={() => setIsTermsAndConditionsChanged(true)}
              />
            )}
        </div>
        {isTermsAndConditionsChanged ? (
          <FormProvider {...methods}>
            <form>
              <div className="mt-1 mb-3">
                <FormTextArea
                  defaultValue={newTermsAndConditions}
                  name="termsAndConditions"
                  label=""
                  placeholder="Enter Terms and Conditions"
                  required
                  rows={10}
                />
              </div>
            </form>
          </FormProvider>
        ) : termsAndConditions ? (
          <pre className="m-3">{termsAndConditions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Terms and Conditions Available for this Quotation" />
          </div>
        )}

        {isTermsAndConditionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setTermsVisible(false);
                  setIsTermsAndConditionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsTermsAndConditionsChanged(false);
                  setNewTermsAndConditions(termsAndConditions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
    </>
  );
};

export default EditQuotedView;
