import React from "react";
import { useNavigate } from "react-router";
import DefaultReportTemplate from "../defaultReportTemplate";
import FormatDate from "../../masters/DateFormater";
import { TooltipComponent } from "../../../components/Component";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";


function DeliveryNoteDetailsReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "GRN #",
      dataIndex: "deliveryNumber",
      key: "deliveryNoteId",
      width: 200,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/grn-preview-report/${record.deliveryNoteId}`, {
                state: { previousPath: "/delivery-note-details-report" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 120,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdByName",
      width: 200,
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNumber",
      key: "vehicleNumber",
      width: 130,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: { previousPath: "/delivery-note-details-report" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
        {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },

    }, 
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`desc-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`desc-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <DefaultReportTemplate
      pageTitle="Delivery Note By Customer"
      moduleKey="deliveryNoteByCustomerReport"
      columns={columns}
      filtersOption={{
        customer: true,
        dateRange: true,
      }}
      previousPath="/view-all-receivables-reports"
      extra={{
        tableScrollX: 1300,
      }}
    />
  );
}

export default DeliveryNoteDetailsReport;
