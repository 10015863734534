import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {  useParams } from "react-router";
import InvoiceComponent from "./InvoiceComponent";
import DispatchAside from "./dispatchAside";
import { Card } from "reactstrap";
function InvoiceToDispatchCreation() {

  let { invoiceNumber }: any = useParams();
  const [sm, setSm] = useState(false);


  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
              <InvoiceComponent invoiceNumber={invoiceNumber} previousPath={"/view-dispatch-invoice-list"}/>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default InvoiceToDispatchCreation;
