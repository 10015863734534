import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency} from "../../../utils/Utils";


function SalesByCustomerReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      // width:300,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoices-for-sales-by-customer-report/${record.customerId}`, {
                state:{
                  name: record.customerName
                }
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
   {
      key: "totalQuotationsRequested",
      title: "Quote Requested",
      dataIndex: "totalQuotationsRequested",
      width: 150,
    },
    {
      key: "totalQuoteSubmitted",
      title: "Quote Responses",
      dataIndex: "totalQuoteSubmitted",
      width: 150,
      render: (text: any, record: any) => (
        <span>
          {text} {"("}
          {Number(record?.quotationResponsePercentage)?.toFixed(2) || 0}%{")"}
        </span>
      ),
    },
    {
      key: "avgResponseTime",
      title: "Average Response Time",
      dataIndex: "avgResponseTime",
      width: 170,
    },
    {
      key: "totalAwards",
      title: "Awards",
      dataIndex: "totalAwards",
      width: 150,
      render: (text: any, record: any) => (
        <span>
          {text} {"("}
          {record?.responseToAwardPercentage || 0}%{")"}
        </span>
      ),
    }, 
    {
      title:"Awarded Value Without Tax",
      dataIndex:"amountWithOutTax",
      key:"amountWithOutTax",
      width: 190,
       render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title:"Awarded Value With Tax",
      dataIndex:"amountWithTax",
      key:"amountWithTax",
      width: 180,
       render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Sales By Customer"
      moduleKey="salesByCustomerReport"
      columns={columns}
      filtersOption={{
        customer: true,
        dateRange: true,
        aging: false,
      }}
      previousPath="/view-all-sales-reports"
      extra={{
        tableScrollX: 1400,
      }}
    />
  );
}

export default SalesByCustomerReport