import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { TooltipComponent } from "../../../components/Component";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";


function PaymentsAgingSummaryReport() {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },

    },

    {
      title: "Outstanding Amount",
      dataIndex: "totalOutstandingAmount",
      key: "totalOutstandingAmount",
      width: 200,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Amount",
      dataIndex: "totalOverdueAmount",
      key: "totalOverdueAmount",
      width: 200,

      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/payments-aging-summary-invoice-report/${record?.customerId}`, {
                state: {
                  name: record?.customerName,
                },
              });
            }}
          >
          {formatCurrency(text?.toString(),record?.currencyCode)}{" ("}{record?.overduePercentage}{"%)"}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Receivables Aging Summary"
      moduleKey="receivablesAgingSummaryReport"
      columns={columns}
      filtersOption={{
        customer:true,
        dateRange: true,
        aging: true,
      }}
      previousPath="/view-all-receivables-reports"
      extra={{
        tableScrollX: 900,
      }}
    />
  );
}

export default PaymentsAgingSummaryReport;